@import url(https://fonts.googleapis.com/css2?family=Questrial&display=swap);
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
   overflow-y:scroll;
   --color-primary: #0a3d62;
   --color-secondary: #079992;
   --color-text: #2c3e50;
}

body, html, button {
  font-family: "Questrial", "Roboto", sans-serif;
  font-size: 15px;
  color: #2c3e50;
  color: var(--color-text);
}

body, html{
  padding: 0;
  margin: 0;
  background-color: #0a3d62;
  background-color: var(--color-primary);
  height: 100%;
}

a {
  color: #079992;
  color: var(--color-secondary);
  text-decoration: none;
}

a:hover{
  color: #0a3d62;
  color: var(--color-primary)

}

.aframe-shit {
  height: 100%;
  max-height: 100%;
  position: relative;
  transition: max-height 0.5s ease;
}

.aframe-shit a-scene {
  position: absolute;
  max-height: 100vw;

  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

}

.aframe-shit.top{
  max-height: 100vw;
  color: #ffd083;
}

button{
  background-color: unset;
  border: none;
  transition: background-color 0.5s;
  text-transform: uppercase;
  padding: 10px 20px 10px 20px;
}

button:hover {
  background-color: rgba(255, 208, 131, 0.26);
}

* { box-sizing: border-box }

#contentContainer {
  width: 100%;
  position: fixed;
  transition: top 0.5s;
  top: 100vh;
}

#contentContainer.open {
  position: absolute;
  top: min(80vh, 100vw);

}

#contentCard {
  padding: 20px;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  cursor: auto;
  min-height: max(20vh, calc(100vh - 100vw));
  position: relative;
  overflow: hidden;
}

.artistMiniature {
  width: 100px;
  height: 100px;
  grid-area: photo;
  object-fit: cover;
}


.artInfo th, .artInfo td {
  text-align: left;

}

.artInfo th {
  text-transform: uppercase;
}

.artInfo td {
  padding-left: 10px;
}


.infoCard{
  display: grid;
  grid-template-columns: 100px minmax(0, 350px) 1fr minmax(200px, 800px);
  grid-template-rows: auto;
  grid-template-areas:
            "info       info        _ content"
            "divider     divider     _ content"
            "description description _ content"
            "buttons     buttons     _ content"
;
  grid-gap: 10px;
  gap: 10px;
}

.infoCard .buttons {
  grid-area: buttons;
  text-align: right;
}

.artInfo {
  grid-area: info;
  /*min-width: 370px;*/
}

.infoCard::before {
  content: "";
  height: 3px;
  display: block;
  background-color: #0a3d62;
  background-color: var(--color-primary);
  grid-area: divider;
}

.artDescription{
  /*border-top: solid 6px var(--color-primary);*/
  grid-area: description;
}

.artContent {
  grid-area: content;
  height: 0;
  overflow: hidden;
  padding-top: calc(9 / 18 * 100%);
  position: relative;
}

.artContent .replaceableContent {
  position: absolute;
  top: 0;
  left: calc(1 / 18 * 100%);
  width: calc(16 / 18 * 100%);
  height: 100%;
  overflow: hidden;
}

.artContent .replaceableContent.noarrows {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%
}

.artContent .vrContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.arrowContainer {
  position: absolute;
  top: 0;
  width: calc(1 / 18 * 100%);
  background-image: url("https://static.newfuture.industra.space/assets/arrow.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
  height: 100%;
}

.arrowContainer:hover {
  opacity: 1;
}

.arrowContainer.right {
  left: calc(17 / 18 * 100%);
}

.arrowContainer.left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.arrowContainer button {
  position: absolute;
  top: 50%;
  width: 100%;
  font-size: 30px;
  padding: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.replaceableContent > *{
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1100px) {
  .infoCard {
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto;
    grid-template-areas:
                    "info info"
                    "content content"
                    "description description"
                    "buttons buttons"
  ;
  }
}

body > .globeControl {
  height: 100%;
}

.globeControl {
  position: relative;
  color: white;
  font-size: 30px;
  font-weight: 500;
}

.globeControl .social {
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: opacity 0.5s;
}

.globeControl .social.hidden {
  opacity: 0;

}

.globeControl .siteName {
  position: absolute;
  top: 20px;
  left: 20px;
  text-transform: uppercase;
}

.globeControl .artistLink {
  position: absolute;
  top: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-transform: uppercase;
}

.globeControl .menu {
  position: absolute;
  top: 20px;
  right: 20px;
  text-transform: uppercase;
}

@media screen and (max-width: 700px) {
  .globeControl .siteName {
    animation: 6s infinite reverse blink;
  }

  .globeControl .artistLink {
    -webkit-transform: none;
            transform: none;
    left: 20px;
    -webkit-animation: 6s infinite blink;
            animation: 6s infinite blink;
  }

  @-webkit-keyframes blink {
    0% { opacity: 0 }
    10% { opacity: 1; }
    40% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
  }

  @keyframes blink {
    0% { opacity: 0 }
    10% { opacity: 1; }
    40% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
  }
}

.socialLink {
  width: 50px;
  height: 50px;
  border: 1px white solid;
  cursor: pointer;
  border-radius: 0;
  display: inline-block;
  padding: 0;
  background-color: white;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  margin-right: 4px;
}

.socialLink.facebook {
  background-image: url(/static/media/facebook.5211aaf1.svg);
}

.socialLink.twitter {
  background-image: url(/static/media/twitter.e18788dc.svg);
}
.socialLink.youtube {
  background-image: url(/static/media/youtube.162aeede.svg);
}

.socialLink.about {
  background-image: url(/static/media/info.f176448c.svg);
}


.socialLink:hover {
  background-color: white;
}

.clickInterceptor{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.cardFade-enter {
  opacity: 0.01;
  position: absolute;
  width: 100%;
}

.cardFade-enter.cardFade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.cardFade-exit {
  position: absolute;
  width: 100%;
  opacity: 1;
}

.cardFade-exit.cardFade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms;
}

h1, h2{
  text-transform: uppercase;
  color: #079992;
  color: var(--color-secondary)
}

.ArtList {
  text-align: center;
}

.SingleArt {
  display: -webkit-inline-flex;
  display: inline-flex;
  height: 240px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
  max-width: 1200px;
  width: 100%;
  text-align: left;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.ArtList h1 {
  text-align: center;
  margin-bottom: 5rem;
}

.SingleArt .artistMiniature {
  height: 100%;
  width: max(200px, 30%)
}

.SingleArt .ArtDescription  {
  height: 100%;
  width: calc(80% - max(230px, 30%));
  overflow: hidden;
}

.SingleArt .buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 20%;
}

.SingleArt .ArtDescription h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
  text-transform: unset;
  max-width: 500px;

}

.SingleArt .ArtDescription p {
  max-width: 500px;
}

.SingleArt .ArtDescription, .SingleArt .artistMiniature {
  cursor: pointer;
  transition: opacity 0.3s;
}

.SingleArt .ArtDescription:hover, .SingleArt .artistMiniature:hover {
  opacity: 0.7;
}

.SingleArt .ArtDescription h3 {
  margin-top: 0;
}


h1:first-child {
  margin-top: 0;
}

.SingleArt {
  border-bottom: 6px solid #0a3d62;
  border-bottom: 6px solid var(--color-primary);
}

.videoPlayer > * {
    height: 100%
}

.videoPlayer video {
    height: 100%;
}
.ImageContainer, .Poster {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: black;
}

.Poster > * {
    position: relative;
    z-index: 3;
}

.ImageContainer img {
    position: relative;
    max-height: 100%;
    border-radius: 4px;
    z-index: 2;

    transition: opacity 0.5s;
}

.ImageTitle {
    position: absolute;
    padding: 20px;
    background-color: black;
    opacity: 0.7;
    color: white;
    bottom: 0;
    width: 100%;
    z-index: 3;
    transition: opacity 0.3s;
}

.ImageTitle:hover {
    opacity: 0.9;
}

.Loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    -webkit-animation: appear 0.5s;
            animation: appear 0.5s;
}

@-webkit-keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.ImageLoading {
    opacity: 0;
}

.ImageLoaded {
    opacity: 1;
}

.ImageBackground {
    --blur: 2rem;
    -webkit-filter: blur(var(--blur));
            filter: blur(var(--blur));
    background-size: cover;
    background-position: center;
    /*background-size: calc(100% + var(--blur));*/
    position: absolute;
    top: calc(-1 * var(--blur));
    left: calc(-1 * var(--blur));
    width: calc(100% + 4 * var(--blur));
    height: calc(100% + 4 * var(--blur));

    margin: calc(-1 * var(--blur));

    transition: background-image 1s;
}

.linkButton {
    display: block;
    color: white;
    background-color: var(--color-secondary);
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
    transition: background-color 0.1s;
}

.linkButton:hover {
    color: white;
    background-color: var(--color-primary);
}
.outOfOrder {
    padding: 10px;
    background-color: var(--color-primary);
    color: white;
}
.Menu {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.90);
    z-index: 5;
    width: 100vw;
    height: 100vh;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    font-size: 5rem;
}

.Menu a {
    color: white;
    transition: color 0.5s;
}

.Menu a::after {
    content: '';
    width: 0;
    height: 0.05em;
    display: block;
    background: white;
    transition: 300ms;
}

.Menu li {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.Menu a:hover::after {
    width: 100%;
}

.Menu a:hover {
    color: #ddd;
}

.Menu nav {
    margin: 50px
}

.Menu ol {
    list-style-type: none;
    padding-left: 0;
}


.closeButton {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 0;
    cursor: pointer;

    position: absolute;
    top: 30px;
    right: 30px;

    display: inline-block;
    padding: 0;
    background-image: url(/static/media/cross.df9a0f34.svg);
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.closeButton:hover {
    background-color: transparent;
}

